import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_V3_RECAPTCHA_SITE_KEY}>
      {element}
    </GoogleReCaptchaProvider>
  )
}

// @todo More investigation needed for a better solution. See https://github.com/gatsbyjs/gatsby/pull/3483
// Always start at the top of the page on a route change.
export const onRouteUpdate = ({ location }) => {
  const { hash } = location
  if (hash) {
    const scrollToElement = document.querySelector(hash)
    if (scrollToElement) {
      scrollToElement.scrollIntoView({
        behavior: 'smooth',
      })
    }
  } else if (typeof window !== `undefined`) {
    window.scrollTo(0, 0)
  }
}

export const shouldUpdateScroll = args => {
  return false
}

// @todo we should consider ditching this if our traffic is not sufficiently seeing anyone using this browser
export const onClientEntry = () => {
  let detectie = require('detectie')
  let ieVersion = detectie() // Returns IE version or false if in non-IE browser
  let shouldDirectToLegacy = ieVersion && ieVersion <= 11 // Redirect IE11 or lower to legacy CTA https://github.com/synapsestudios/synapse-site/issues/123

  if (shouldDirectToLegacy) {
    window.location.replace('https://www.synapsestudios.com/index-legacy.html')
  }
}
